import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ShoreSection from "./ShoreSection";
import JumbotronSection from "./JumbotronSection";
import Weather from "./Weather";
import { detectLocale, locales } from "./i18n/i18n-util";
import { loadLocale } from "./i18n/i18n-util.sync";
import { navigatorDetector } from "typesafe-i18n/detectors";
import TypesafeI18n, { useI18nContext } from "./i18n/i18n-react";
import { loadLocaleAsync } from "./i18n/i18n-util.async";
import { DataContext, DataProvider } from "./DataContext";
import {
  BrowserRouter,
  HashRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";

const detectedLocale = detectLocale(navigatorDetector);

const BaseComponent = () => {
  const { pathname } = useLocation();
  const dataContext = React.useContext(DataContext);

  React.useEffect(() => {
    dataContext.onHeaderItemSelect(0);
    dataContext.fetchTides();
    if (pathname.includes("offshore")) {
      dataContext.fetchWeather("offshore");
    } else {
      dataContext.fetchWeather("nearshore");
    }
    // console.log(pathname);
  }, [pathname]);

  return (
    <div>
      {/* <Header />
      <ShoreSection /> */}
      <JumbotronSection />
      <Weather />
      {/* <Footer /> */}
    </div>
  );
};

const Layout = ({ locale }: { locale: string }) => {
  return (
    <div className={`App`}>
      <Header />
      <ShoreSection />
      <Outlet />
      <Footer />
    </div>
  );
};

function App() {
  const [localesLoaded, setLocalesLoaded] = React.useState(false);
  const { locale } = useI18nContext();

  React.useEffect(() => {
    loadLocaleAsync(detectedLocale).then(() => setLocalesLoaded(true));
  }, []);

  if (!localesLoaded) return null;

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={15000}
      preventDuplicate
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <TypesafeI18n locale={detectedLocale}>
        <DataProvider>
          <HashRouter>
            <Routes>
              {/* <Route path="/" element={<Navigate replace to={"/nearshore"} />}> */}
              <Route path="/" element={<Layout locale={locale} />}>
                <Route path="/nearshore" index element={<BaseComponent />} />
                <Route path="/offshore" element={<BaseComponent />} />
                <Route
                  path="/"
                  element={<Navigate replace to={"/nearshore"} />}
                />
                {/* <Route
                path="*"
                element={<Navigate replace to={"/nearshore"} />}
              /> */}
              </Route>
            </Routes>
          </HashRouter>
        </DataProvider>
      </TypesafeI18n>
    </SnackbarProvider>
  );
}

export default App;
