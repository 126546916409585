import React from "react";
import HoursStepTabItem from "./HoursStepTabItem";
import { DayBase } from "./models";

type Props = {
  data: DayBase;
  isRTL: boolean;
  tides: any;
};

const WeatherContentTab = (props: Props) => {
  //   const ulRef = React.useRef<HTMLDivElement>(null);
  //   const [currentIndex, setCurrentIndex] = React.useState(0);

  //   React.useEffect(() => {
  //     const handleScroll = () => {
  //       if (ulRef.current) {
  //         const scrollPosition = ulRef.current?.scrollLeft;
  //         const containerWidth = ulRef.current.offsetWidth;
  //         const listItems = ulRef.current?.querySelectorAll("li");

  // console.log("LIST ITEMS:::", listItems);
  // let newIndex = -1;
  // let minDistance = Infinity;

  // listItems.forEach((item: any, index: number) => {
  //   const itemOffsetLeft = item.offsetLeft;
  //   const itemWidth = item.offsetWidth;

  //   if (
  //     scrollPosition >= itemOffsetLeft &&
  //     scrollPosition < itemOffsetLeft + itemWidth
  //   ) {
  //     if (index >= 0 && index <= 3) {
  //       console.log(index);
  //       setCurrentIndex(index);
  //     }
  //   }
  //   if (ulRef.current?.offsetLeft) {
  //     const itemOffsetLeft = item.offsetLeft - ulRef.current.offsetLeft;
  //     const itemWidth = item.offsetWidth;
  //     const itemCenter = itemOffsetLeft + itemWidth / 2;
  //     const distance = Math.abs(
  //       scrollPosition + containerWidth / 2 - itemCenter
  //     );
  //     if (distance < minDistance) {
  //       minDistance = distance;
  //       newIndex = index;
  //     }
  //   }
  //     });
  //   }
  // };

  //     if (ulRef.current) {
  //       ulRef.current.addEventListener("scroll", handleScroll, true);
  //     }

  //     return () => {
  //       if (ulRef.current) {
  //         ulRef.current.removeEventListener("scroll", handleScroll);
  //       }
  //     };
  //   }, []);

  //   const scrollEvent = (e: React.SyntheticEvent) => {
  //     const target = e.target as HTMLTextAreaElement;
  //     console.log("Current scroll position:", target.scrollLeft);
  //   };

  return (
    <div
      // className="my-own-custom-container"
      className="container d-flex justify-content-between align-items-stretch flex-column"
    >
      {props.data.SixHourSteps ? (
        <div
          //   ref={ulRef}
          //   onScroll={scrollEvent}
          className="weather-header-container w-100"
        >
          <ul
            className={`weather-content-tab ${
              props.isRTL ? "weather-content-tab-ar" : null
            }`}
          >
            {props.data.SixHourSteps.map((d, i) => (
              <li key={`${i}-h`}>
                <HoursStepTabItem data={d} tides={props.tides} />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {/* <div className="d-flex justify-content-center align-items-center mt-3 gap-3">
        {props.data.SixHourSteps.map((d, i) => (
          <span
            className={`dot${i === currentIndex ? "-active" : ""}`}
            key={`dot-${i}`}
          ></span>
        ))}
      </div> */}
    </div>
  );
};

export default WeatherContentTab;
