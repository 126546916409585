import React from "react";
import Weather from "./Weather";
import { useI18nContext } from "./i18n/i18n-react";
import { useContext } from "react";
import { DataContext } from "./DataContext";
import {
  getCardinalDirection,
  getHour,
  metersToMiles,
  getDayName,
  sameDay,
} from "./helpers";
import moment from "moment";
import WeatherHeaderTab from "./WeatherHeaderTab";

type Props = {};

const JumbotronSection = (props: Props) => {
  const { LL, locale } = useI18nContext();
  const context = useContext(DataContext);

  // const [city, setCity] = React.useState("");

  // React.useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(getCityName);
  //   }
  // }, []);

  // const getCityName = async (position: any) => {
  //   const { latitude, longitude } = position.coords;

  //   try {
  //     const response = await fetch(`https://geocode.xyz/${latitude},${longitude}?json=1`);
  //     const data = await response.json();

  //     if (response.ok) {
  //       const city = data.city || '';
  //       setCity(city);
  //     } else {
  //       console.log('Unable to retrieve city name.');
  //     }
  //   } catch (error) {
  //     console.log('Error:', error);
  //   }
  // };

  const currentDate = moment();
  const formattedDate = currentDate.locale(locale).format("dddd, DD MMMM YYYY");

  const getCurrentTide = () => {
    const data = context.tidesData;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (
          sameDay(moment(element.ForecastTime).toDate(), currentDate.toDate())
        ) {
          if (
            getHour(currentDate.toLocaleString()) >=
              getHour(element.ForecastTime) &&
            getHour(currentDate.toLocaleString()) <=
              getHour(element.ForecastTime)
          ) {
            return element;
          } else {
            return data[0];
          }
        }
      }
    }
  };

  const getCurrentWeather = () => {
    const data = context.daysWeatherData;

    console.log("====>>>>>>", data);
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const ele = data[i];
        if (
          sameDay(
            moment(ele.Day.ForecastTimeIndicator).toDate(),
            currentDate.toDate()
          )
        ) {
          for (let index = 0; index < data[i]?.SixHourSteps.length; index++) {
            const element = data[i]?.SixHourSteps[index];
            if (element) {
              if (
                getHour(currentDate.toLocaleString()) >=
                  getHour(element.StartDate) &&
                getHour(currentDate.toLocaleString()) <=
                  getHour(element.EndDate)
              ) {
                return element;
              }
            }
          }
        }
      }
    }
  };

  // console.log("CURRENT WEATHER --->>>", getCurrentWeather());

  return (
    <>
      <div className="container-fluid header-bg p-0">
        <div
          className="container justify-content-center"
          dir={locale === "ar" ? "rtl" : "ltr"}
        >
          <div className="col-sm-12">
            <div className="row">
              <div className="col-xl-8 col-lg-7 col-md-4 col-sm-12">
                <div className="tcd">
                  <div className="time">
                    {`${currentDate.format("HH:mm")}`}
                    <span className="ms-2">{`${currentDate.format("A")}`}</span>
                  </div>

                  <div className="country">
                    {locale === "en"
                      ? "Dubai, UAE"
                      : "دبي، الإمارات العربية المتحدة"}
                  </div>
                  <div className="date">{formattedDate}</div>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-5 col-md-7 col-sm-12 currentweather"
                dir="ltr"
              >
                {/* <div className="inner-currentweather d-flex"> */}
                {getCurrentWeather() ? (
                  <div
                    className={`d-flex justify-content-between align-items-start ${
                      locale === "ar" ? "flex-row-reverse" : "flex-row"
                    } bg-white rounded p-3 gap-lg-2 gap-5`}
                  >
                    <div className="firstdiv d-none d-md-block">
                      <span className="bold l-h26">{LL.current_weather()}</span>
                      <br />
                      <div className="d-flex align-items-baseline gap-2">
                        <span className="fs34 bold c-blue">
                          {getCurrentWeather()?.WaveHeight}
                        </span>{" "}
                        <span className="c-blue fw-normal fs-6">ft</span>
                      </div>
                      {/* <br /> */}
                      <p className="overflow-hidden text-nowrap">
                        {LL.wave_height()}
                      </p>
                    </div>
                    {/* <div className="secdiv"> */}
                    <div className="w-100">
                      <h6 className="fs-5 bold text-center w-100 mb-3 d-block d-md-none">
                        {LL.current_weather()}
                      </h6>
                      <ul className="p-0 m-0">
                        <li className="overflow-hidden text-nowrap border-bottom d-block d-md-none py-1">
                          <div className="row" style={{ height: "35px" }}>
                            <div className="col text-end">
                              <span className="fs-1 bold c-blue">
                                {getCurrentWeather()?.WaveHeight}
                              </span>
                              <span className="c-blue fs-6 fw-normal ms-2">
                                ft
                              </span>
                            </div>
                            <div className="col mt-085">{LL.wave_height()}</div>
                          </div>
                        </li>
                        <li className="overflow-hidden text-nowrap border-bottom py-1">
                          <div className="row">
                            {getCurrentWeather()?.WaveDirection ? (
                              <div className="col text-end bold">
                                {getCardinalDirection(
                                  getCurrentWeather()?.WaveDirection ?? 0
                                )}
                              </div>
                            ) : null}
                            <div className="col">{LL.wave_direction()}</div>
                          </div>
                        </li>
                        <li className="overflow-hidden text-nowrap border-bottom py-1">
                          <div className="row">
                            {getCurrentWeather()?.Temperature ? (
                              <div className="col text-end bold">
                                {`${Math.round(
                                  getCurrentWeather()?.Temperature!
                                )}°C`}
                              </div>
                            ) : null}
                            <div className="col">{LL.air_temperature()}</div>
                          </div>
                        </li>
                        <li className="overflow-hidden text-nowrap border-bottom py-1">
                          <div className="row">
                            {getCurrentWeather()?.WindSpeed ? (
                              <div className="col text-end bold">
                                {`${Math.round(
                                  getCurrentWeather()!.WindSpeed
                                )} kts`}
                              </div>
                            ) : null}
                            <div className="col">{LL.wind_speed()}</div>
                          </div>
                        </li>
                        <li className="overflow-hidden text-nowrap border-bottom py-1">
                          <div className="row">
                            {/* {getCurrentWeather()?.WindDirection ? ( */}
                            {getCurrentWeather()?.WindDirection ? (
                              <div className="col text-end bold">
                                {getCardinalDirection(
                                  getCurrentWeather()?.WindDirection ?? 0
                                )}
                              </div>
                            ) : null}
                            {/* ) : null} */}
                            {/* <span>
                          <span className="material-symbols-outlined">
                            north_west
                          </span>
                          NW
                        </span> */}
                            <div className="col">{LL.wind_direction()}</div>
                          </div>
                        </li>
                        {getCurrentWeather()?.VisibilityMeters ? (
                          <li className="overflow-hidden text-nowrap border-bottom py-1">
                            <div className="row">
                              <div className="col text-end bold">{`${metersToMiles(
                                getCurrentWeather()!.VisibilityMeters
                              )}mil`}</div>
                              <div className="col">{LL.visibility()}</div>
                            </div>
                          </li>
                        ) : null}
                        <li className="overflow-hidden text-nowrap border-bottom py-1">
                          <div className="row">
                            <div className="col text-end bold">
                              {getCurrentTide()?.ElevationLat ?? ""}
                            </div>
                            <div className="col">{LL.tide()}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : context.loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between align-items-center flex-column bg-white rounded p-3 gap-3">
                    <span className="bold l-h26">{LL.current_weather()}</span>
                    <span className="bold text-align-center align-self-center text-black-50">
                      {LL.somethingWentWrong()}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {context.daysWeatherData ? (
          <div className="weather-tab">
            <WeatherHeaderTab
              data={context.daysWeatherData}
              onItemClicked={(i: number) =>
                context.carouselRef?.current?.goToSlide(i)
              }
              // onItemClicked={(i: number) => context.onHeaderItemSelect(i)}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default JumbotronSection;
