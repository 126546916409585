import React from "react";
import { DayBase, Weather } from "./models";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import Carousel from "react-multi-carousel";
import { sameDay } from "./helpers";
import moment from "moment";

interface IContextData {
  daysWeatherData: DayBase[] | null;
  tidesData: any;
  loading: boolean;
  fetchTides: () => void;
  fetchWeather: (type: "offshore" | "nearshore") => void;
  onHeaderItemSelect: (index: number) => void;
  selectedIndex: number;
  carouselRef?: React.RefObject<Carousel>;
}

const DataContext = React.createContext<IContextData>({
  daysWeatherData: null,
  tidesData: null,
  loading: true,
  fetchTides: () => {},
  fetchWeather: (type) => {},
  onHeaderItemSelect: (index) => {},
  selectedIndex: 0,
  carouselRef: undefined,
});

const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const [weatherData, setWeatherData] = React.useState<DayBase[] | null>(null);
  const [tidesData, setTidesData] = React.useState<any | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [apiKey, setApiKey] = React.useState("");
  const ref = React.useRef<Carousel>(null);
  const { enqueueSnackbar } = useSnackbar();

  // React.useEffect(() => {
  //   console.log("THIS IS THE KEY:::", apiKey);
  //   (async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://webapi.stormgeo.com/api/v1/keygen/k00mb4ya"
  //       );

  //       if (response.status === 200) {
  //         if (response.data.Key) {
  //           // console.log("THIS IS THE KEY:::", response.data.Key);
  //           setApiKey(response.data.Key);
  //         }
  //       }
  //     } catch (e) {
  //       let er = e as AxiosError;
  //       enqueueSnackbar(`${er.message ?? "Error getting data"}`, {
  //         variant: "error",
  //       });
  //     }
  //   })();
  // }, []);

  // React.useEffect(() => {
  //   console.log(apiKey);
  // }, [apiKey]);

  const fetchPlace = async () => {
    const response = await axios.get(
      "https://webapi.stormgeo.com/api/v1/places/104279908?auth=%2FN4jWtESmAabdo4CdtuWeANSKnuMGrHWwdnX0HXqg%2FQ%3D&language=en"
    );
    // console.log(response);
  };

  const fetchTides = async () => {
    try {
      let tApiKey;
      if (!apiKey.length) {
        const apiKeyResponse = await axios.get(
          "https://webapi.stormgeo.com/api/v1/keygen/r151k0b4n4n"
        );

        if (apiKeyResponse.status !== 200) {
          enqueueSnackbar("Error in authentication", {
            variant: "error",
          });
          return;
        }
        tApiKey = apiKeyResponse.data.Key;
        setApiKey(apiKeyResponse.data.Key);
      }
      const response = await axios.get(
        `https://webapi.stormgeo.com/api/v1/tides/104279908?auth=${
          apiKey.length ? apiKey : tApiKey
        }&days=9&unitType.length=ft`
      );
      if (response.status === 200) {
        const data = response.data;
        if (data?.OneHourSteps) {
          setTidesData(data.OneHourSteps);
        }
      }
    } catch (e) {
      let er = e as AxiosError;
      enqueueSnackbar(`${er.message ?? "Error getting data"}`, {
        variant: "error",
      });
    }
  };

  const fetchWeatherData = async (type: "nearshore" | "offshore") => {
    // console.log("Fetching...");
    try {
      let tApiKey;
      if (!apiKey.length) {
        console.log("2");
        const apiKeyResponse = await axios.get(
          "https://webapi.stormgeo.com/api/v1/keygen/r151k0b4n4n"
        );

        if (apiKeyResponse.status !== 200) {
          enqueueSnackbar("Error in authentication", {
            variant: "error",
          });
          return;
        }
        tApiKey = apiKeyResponse.data.Key;
        setApiKey(apiKeyResponse.data.Key);
      }
      let newData = [] as DayBase[];
      const response = await axios.get(
        `https://webapi.stormgeo.com/api/v1/long-term-forecast/${
          type === "nearshore" ? `104279908` : `104279909`
        }?auth=${
          apiKey.length ? apiKey : tApiKey
        }&load=apparenttemperature,precprob,windgust,humidity,tide,thunderRiskIndex,waveDirection,waveHeight,visibilityMeters&unitType.length=ft&unitType.speed=kts&unitType.temperature=c`
      );
      if (response.status === 200) {
        const data = response.data as Weather;
        for (let index = 0; index < data?.Days?.length; index++) {
          const element = data?.Days[index];
          if (
            !newData.some((x) =>
              sameDay(
                moment(x.Day.ForecastTimeIndicator).toDate(),
                moment(element.Day.ForecastTimeIndicator).toDate()
              )
            )
          ) {
            newData.push(element);
          }
        }
        if (data?.Days?.length) {
          setWeatherData(newData.slice(0, 7));
          // setWeatherData(data.Days?.slice(0, 7));
        }
      } else {
        // console.log(response);
      }
    } catch (error) {
      let er = error as AxiosError;
      // console.log(error);
      enqueueSnackbar(`${er.message ?? "Error getting data"}`, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  // React.useEffect(() => {
  //   fetchWeatherData("nearshore");
  //   // fetchPlace();
  //   return () => {};
  // }, []);

  return (
    <DataContext.Provider
      value={{
        daysWeatherData: weatherData,
        tidesData: tidesData,
        loading,
        fetchTides: fetchTides,
        fetchWeather: fetchWeatherData,
        onHeaderItemSelect: (index) => setSelectedIndex(index),
        selectedIndex,
        carouselRef: ref,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
