import React, { useContext } from "react";
import { DayBase } from "./models";
import { getDayName } from "./helpers";
import { useI18nContext } from "./i18n/i18n-react";
import { useLocation } from "react-router-dom";
import { DataContext } from "./DataContext";
import SvgIcon from "./SvgIcon";

type Props = {
  data: DayBase[];
  onItemClicked: (i: number) => void;
};

const WeatherHeaderTab = (props: Props) => {
  const { pathname } = useLocation();
  const { locale } = useI18nContext();
  // const dataContext = useContext(DataContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    props.onItemClicked(0);
    setSelectedIndex(0);
    // dataContext.onHeaderItemSelect(0);
    // if (pathname.includes("offshore")) {
    //   dataContext.fetchWeather("offshore");
    // } else {
    //   dataContext.fetchWeather("nearshore");
    // }
    // console.log(pathname);
  }, [pathname]);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    const container = containerRef.current;
    if (container) {
      if (locale === "ar") {
        container.scrollTo({
          left: container.scrollLeft + container.offsetWidth,
          behavior: "smooth",
        });
      } else {
        container.scrollTo({
          left: container.scrollLeft - container.offsetWidth,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollRight = () => {
    const container = containerRef.current;
    if (container) {
      if (locale === "ar") {
        container.scrollTo({
          left: container.scrollLeft - container.offsetWidth,
          behavior: "smooth",
        });
      } else {
        container.scrollTo({
          left: container.scrollLeft + container.offsetWidth,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div
      className="container d-flex justify-content-between align-items-stretch"
      style={{ height: "160px" }}
      dir={locale === "ar" ? "rtl" : "ltr"}
    >
      {/* <div className="h-100">
        <button
          onClick={scrollLeft}
          className="control-button-prev bg-light h-100"
        >
          {"<<"}
        </button>
      </div> */}
      <div className="weather-header-container w-100" ref={containerRef}>
        <ul
          className={`weather-header-tab ${
            locale === "ar" ? "weather-header-tab-ar" : null
          }`}
        >
          {props.data.map((d, i) => (
            <li
              key={i}
              className={`d-flex flex-column justify-content-between align-items-center ${
                selectedIndex === i ? "active" : ""
              }`}
              onClick={() => {
                props.onItemClicked(i);
                setSelectedIndex(i);
              }}
            >
              <span className="fd-bold">
                {getDayName(d.Day.ForecastTimeIndicator, locale)}
              </span>
              <SvgIcon name={`#symbol-${d.Day.Symbol}`} />
              {/* <svg>
                <use xlinkHref={`symbol-10`}></use>
              </svg> */}
              {/* <img src={`#sg-symbol${d.Day.Symbol}`} alt="" /> */}
              {/* <span className="material-symbols-outlined">
                {symbols[d.Day.Symbol] ?? ""}
              </span> */}
              <span>
                H {Math.round(d.Day.TemperatureMax)}° L{" "}
                {Math.round(d.Day.TemperatureMin)}°
              </span>
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="h-100">
        <button onClick={scrollRight} className="control-button-next bg-light">
          {">>"}
        </button>
      </div> */}
    </div>
  );
};

export default WeatherHeaderTab;
