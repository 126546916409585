import moment from "moment";
import {
  getCardinalDirection,
  getHour,
  metersToMiles,
  sameDay,
} from "./helpers";
import { useI18nContext } from "./i18n/i18n-react";
import { DayDetails, HoursStep } from "./models";

type Props = {
  data: HoursStep;
  tides: any;
};

const HoursStepTabItem = (props: Props) => {
  const { LL, locale } = useI18nContext();

  const getTide = () => {
    const data = props.tides;
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (
        sameDay(
          moment(element.ForecastTime).toDate(),
          moment(props.data.ForecastTime).toDate()
        )
      ) {
        if (
          getHour(element.ForecastTime.toLocaleString()) >=
            getHour(props.data.ForecastTime) &&
          getHour(element.ForecastTime.toLocaleString()) <=
            getHour(props.data.ForecastTime)
        ) {
          return element;
        } else {
          return data[0];
        }
      }
    }
  };

  return (
    <div
      className="border rounded p-3 py-4"
      dir="ltr"
      style={{ backgroundColor: "#f6f6f6" }}
    >
      <div className="weakly-hours">
        <div className="text-center">
          <div
            className="fd-bold"
            dir={locale === "ar" ? "rtl" : "ltr"}
          >{`${getHour(props.data.StartDate)} - ${getHour(
            props.data.EndDate
          )}`}</div>
          <div className="color bold">
            {props.data.WaveHeight} <span className="regular">ft</span>
          </div>
          {LL.wave_height()}
        </div>
        <div className="weather-condition">
          <ul>
            <li>
              <span className="bold">
                {getCardinalDirection(props.data.WaveDirection)}
              </span>
              {/* <span>
                <span className="material-symbols-outlined">north_west</span>
                NW
              </span> */}
              {LL.wave_direction()}
            </li>
            <li>
              <span className="bold">
                {`${Math.round(props.data.Temperature)}°C`}{" "}
              </span>
              {LL.air_temperature()}
            </li>
            <li>
              <span className="bold">
                {`${Math.round(props.data.WindSpeed)} kts`}{" "}
              </span>
              {LL.wind_speed()}
            </li>
            <li>
              <span className="bold">
                {getCardinalDirection(props.data.WindDirection)}
              </span>
              {/* <span>
                <span className="material-symbols-outlined">north_west</span>
                NW
              </span>{" "} */}
              {LL.wind_direction()}
            </li>
            <li>
              <span className="bold">{`${metersToMiles(
                props.data.VisibilityMeters
              )}mil`}</span>
              {LL.visibility()}
            </li>
            {/* <li>
              <span>{getTide()?.elevationLat ?? ""}</span>
              {LL.tide()}
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HoursStepTabItem;
