import { useI18nContext } from "./i18n/i18n-react";
import { Locales } from "./i18n/i18n-types";
import { loadLocaleAsync } from "./i18n/i18n-util.async";
import { locales } from "./i18n/i18n-util";
import { ChangeEventHandler } from "react";

function LanguageSelection() {
  const { LL, locale, setLocale } = useI18nContext();

  const onLocaleSelected: ChangeEventHandler<HTMLSelectElement> = async ({
    target,
  }) => {
    const locale = target.value as Locales;
    localStorage.setItem("lang", locale);
    await loadLocaleAsync(locale);
    setLocale(locale);
  };

  return (
    <label>
      <select
        // className="form-select bg-transparent border border-0 shadow-none"
        className="language-select"
        value={locale || ""}
        onChange={onLocaleSelected}
        style={{
          backgroundColor: "#2b6182",
          color: "white",
          border: "none",
          boxShadow: "none",
          outline: "none",
          // textTransform: "uppercase",
        }}
      >
        {locales.map((locale) => (
          <option
            key={locale}
            value={locale}
            style={{
              color: "white",
            }}
          >
            {locale === "ar" ? "عربي" : "English"}
          </option>
        ))}
      </select>
    </label>
  );
}

export default LanguageSelection;
