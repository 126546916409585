import React from "react";
import StormLogo from "./images/storm.png";
import { useI18nContext } from "./i18n/i18n-react";

type Props = {};

const Footer = (props: Props) => {
  const { LL, locale } = useI18nContext();
  return (
    <div className="container pt-5 pb-3">
      <div className="row d-flex justify-content-between">
        <div className="col-lg-7 copyright mb-2">
          {LL.copyright({ year: "2023" })} <br className="d-block d-md-none" />
          <a
            className="disclaimer"
            // href="https://disclaimer.pcfc.ae/"
            href={
              locale === "ar"
                ? "https://pcfc.ae/ar/Pages/Terms-and-Condition.aspx"
                : "https://pcfc.ae/en/Pages/Terms-and-Condition.aspx"
            }
          >
            {LL.disclaimer()}
          </a>
        </div>
        <div
          className="col-lg-5 the-weather-report-i"
          dir={locale === "ar" ? "rtl" : "ltr"}
        >
          {LL.weather_delivered_by()}
          <a href="https://www.stormgeo.com/">
            <img src={StormLogo} width="84" alt="StormGeo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
