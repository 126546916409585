import moment from "moment";

export function getDayName(dateStr: string, locale: string) {
  return moment(dateStr).locale(locale).format("ddd")?.toUpperCase();
}

export function getHour(dateStr: string) {
  // return moment(dateStr).hour();
  const d = new Date(dateStr);
  return d.getHours();
}

export function sameDay(d1: Date, d2: Date) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function metersToMiles(meters: number) {
  return Math.round(meters / 1609);
}

export function getCardinalDirection(angle: number) {
  // let degree = (angle * 8) / 360;
  // degree = Math.round(degree);
  // degree = (degree + 8) % 8;
  const directions = [
    "↑ N",
    "↗ NE",
    "→ E",
    "↘ SE",
    "↓ S",
    "↙ SW",
    "← W",
    "↖ NW",
  ];
  // return directions[degree];
  return directions[Math.round(angle / 45) % 8];
}

// function getWindDirection(d: number) {
//   let WIND_DIRECTION;
//   switch (d) {
//     case 0:
//     case 360:
//       WIND_DIRECTION = "N";
//       break;
//     case 90:
//       WIND_DIRECTION = "E";
//       break;
//     case 180:
//       WIND_DIRECTION = "S";
//       break;
//     case 270:
//       WIND_DIRECTION = "W";
//       break;
//     case d > 0 && d < 90:
//       WIND_DIRECTION = "NE";
//       break;
//     case d > 90 && d < 180:
//       WIND_DIRECTION = "SE";
//       break;
//     case d > 180 && d < 270:
//       WIND_DIRECTION = "SW";
//       break;
//     case d > 270 && d < 360:
//       WIND_DIRECTION = "NW";
//       break;
//     default:
//       WIND_DIRECTION = "-";
//       break;
//   }

//   return WIND_DIRECTION;
// }

// export const degToCard = function (deg: any) {
//   if (deg > 11.25 && deg < 33.75) {
//     return "NNE";
//   } else if (deg > 33.75 && deg < 56.25) {
//     return "ENE";
//   } else if (deg > 56.25 && deg < 78.75) {
//     return "E";
//   } else if (deg > 78.75 && deg < 101.25) {
//     return "ESE";
//   } else if (deg > 101.25 && deg < 123.75) {
//     return "ESE";
//   } else if (deg > 123.75 && deg < 146.25) {
//     return "SE";
//   } else if (deg > 146.25 && deg < 168.75) {
//     return "SSE";
//   } else if (deg > 168.75 && deg < 191.25) {
//     return "S";
//   } else if (deg > 191.25 && deg < 213.75) {
//     return "SSW";
//   } else if (deg > 213.75 && deg < 236.25) {
//     return "SW";
//   } else if (deg > 236.25 && deg < 258.75) {
//     return "WSW";
//   } else if (deg > 258.75 && deg < 281.25) {
//     return "W";
//   } else if (deg > 281.25 && deg < 303.75) {
//     return "WNW";
//   } else if (deg > 303.75 && deg < 326.25) {
//     return "NW";
//   } else if (deg > 326.25 && deg < 348.75) {
//     return "NNW";
//   } else {
//     return "N";
//   }
// };
