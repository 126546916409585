import React from "react";

type Props = {
  name: string;
};

const SvgIcon = (props: Props) => {
  return (
    <svg width="100" height="75" xmlns="http://www.w3.org/2000/svg">
      <symbol id="symbol-1" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M127.5 49.2c-3 0-5.5-2.4-5.5-5.3V6c0-3 2.6-5.3 5.5-5.3S133 3.2 133 6v38c0 2.8-2.6 5.2-5.5 5.2zm0 206.5c-3 0-5.5-2.3-5.5-5.2v-37.3c0-3 2.6-5.2 5.5-5.2s5.5 2.3 5.5 5.2v37.3c0 3-2.6 5.2-5.5 5.2zm75.3-25.7c-1.5 0-3-.7-4-2l-23-29.2c-1.6-2.3-1.2-5.6 1-7.4 2.3-1.8 5.6-1.4 7.4 1l22.8 29c1.8 2.4 1.4 5.7-1 7.4-1 .8-2 1-3.2 1zM75.3 66.7c-1.5 0-3-.7-4-2l-23-29c-1.6-2.4-1.2-5.7 1-7.5 2.3-1.8 5.6-1.4 7.4 1l22.8 29c1.7 2.4 1.3 5.6-1 7.4-1 .8-2 1-3.2 1zM177.7 65c-1 0-2.2-.4-3-1-2.4-1.8-3-5-1.2-7.4l21.7-30c1.7-2.2 5-2.8 7.3-1 2.4 1.6 3 5 1.2 7.3L182 62c-1 1.4-2.7 2.2-4.3 2.2zM56 232.5c-1 0-2.2-.3-3-1-2.5-1.7-3-5-1.3-7.3l21.8-30c1.7-2.3 5-2.8 7.3-1 2.3 1.6 2.8 5 1 7.2l-21.6 30c-1 1.4-2.6 2-4.2 2zm152.6-125c-2.2 0-4.3-1.4-5-3.6-1-3 .6-6 3.4-7l35.2-12c2.7-1 5.7 0 6.6 3 1 2-.7 5-3.4 6l-35.2 12h-1.6zm-197 64c-2.2 0-4.2-1.4-5-3.6-.8-3 .7-6 3.4-7l35.2-12c2.7-1 5.7 0 6.6 3 1 3-.6 5-3.4 6l-35 11h-1.8zm232.2 0c-.6 0-1 0-1.6-.3L207 159.8c-2.8-1-4.3-3.8-3.4-6.6 1-2.8 4-4.3 6.6-3.4l35.2 11.5c2.7 1 4.3 3.8 3.4 6.6-.8 2-2.8 3-5 3zm-197-64c-.5 0-1 0-1.6-.3L10 95.8c-2.7-1-4.3-4-3.4-6.6 1-2.8 4-4.3 6.7-3.4l35 11.5c3 .8 4.4 3.8 3.5 6.6-.7 2-2.8 3-5 3z"
        />
        <circle cx="127.7" cy="128.5" r="63.4" fill="#FFB900" />
      </symbol>
      <symbol id="symbol-10" viewBox="0 0 256 256">
        <path
          fill="#4196C8"
          d="M74 220.3c-1 0-2-.4-3-1-2.2-1.7-2.8-5-1-7.2l17-23c1.6-2 4.8-2 7-1 2.4 2 3 5 1.2 7l-17 24c-1 2-2.6 2-4 2zm29.3 0c-1 0-2.2-.4-3-1-2.4-1.8-2.8-5-1-7.2l17.4-23c1.7-2 5-3 7.2-1 2 2 2 5 1 7l-18 24c-1 2-3 2-4 2zm31.7 0c-1 0-2-.4-3-1-2.2-1.7-2.7-5-1-7.2l17-23c1.6-2 4.8-3 7-1 2.4 2 3 5 1.3 7l-17 24c-1 2-2.6 2-4.2 2zm30 0c-1.2 0-2.2-.4-3-1-2.4-1.7-3-5-1.3-7.2l17-23c1.7-2 5-3 7.2-1 2 2 2 5 1 7l-17 24c-1 2-3 2-4 2z"
        />
        <path
          fill="#666"
          d="M181.8 76c-2.7 0-5.3.2-7.8.7-3.8-21.2-21-37.6-42.7-40.2l-6-.3c-6 0-12 1-17.5 3.2-18.5 7-31.7 25-31.7 46v1.3c-2-.4-4-.6-7-.6-13 0-24 7-32 17-5 8-8 17-8 26 0 23 18 43 41 43h112c25 0 46-22 46-47s-21-49-46-49z"
        />
      </symbol>
      <symbol id="symbol-11" viewBox="0 0 256 256">
        <path
          fill="#666"
          d="M181.6 76.5c-2.6 0-5.3.2-7.8.6-3.8-21-21-37-42.7-40h-5c-6 0-12 1-17 3-19 7-32 25-32 46v2h-7c-13 0-25 7-32 17v1c-5 7-8 16-8 25 0 23 18 42 41 42h112c25 0 46-22 46-47s-20-48-46-48z"
        />
        <path
          fill="#4196C8"
          d="M73 220c-1 0-2.2-.4-3-1-2.4-1.7-3-5-1.2-7.2l17-23.4c1.7-2.3 5-2.8 7.2-1.2 2.3 1.7 2.8 5 1 7.2l-17 23.4c-1 1.4-2.5 2.2-4 2.2zm38.3-11.8c-1 0-2-.3-3-1-2.3-1.6-3-4.8-1.2-7l9-11.8c2-2.3 5-2.8 7-1.2 3 1.7 3 5 1 7.2l-8 11.6c-1 2-2 2.7-4 2.7zm54.2 11.8c-1 0-2-.4-3-1-2.3-1.7-3-5-1.2-7.2l17-23.4c1.7-2.3 5-2.8 7.2-1.2 2.3 1.7 2.8 5 1 7.2l-17 23.4c-1 1.4-2.4 2.2-4 2.2z"
        />
        <path
          fill="#FFB900"
          d="M170 132.7c-2.2-1.7-5.5-1.2-7.3 1.2l-32.2 43c-1.7 2-1.2 5 1 7 1 0 2.2 1 3.3 1H149l-18 26c-1.7 2-1.2 5 1.2 7 1 0 2 1 3 1 1.8 0 3.4-1 4.4-3l24-33c1.7-2 1-5-1.2-7h-.3c-1-1-2-1-3-1h-14l27-36c2-2 1-6-1-7z"
        />
      </symbol>
      <symbol id="symbol-12" viewBox="0 0 256 256">
        <path
          fill="#E6E6E6"
          d="M210.6 205l-10-3.4 5.8-7.8c1.8-2.2 1.3-5.5-1-7.2-2.2-1.7-5.4-1.2-7.2 1l-6.7 9-6.7-8.3c-1.8-2.2-5-2.5-7.2-.7-2.2 1.7-2.6 5-.8 7.2l6.7 8.3-9.2 4c-2.6 1-4 4-3 7 .7 2 2.7 4 4.8 4h2l10-3v10c0 3 3 6 5 6 3 0 6-2.2 6-5v-11l10 3h2c2 0 4-1.2 5-3.4 1-2.5 0-5.4-3-6.3z"
        />
        <path
          fill="#4196C8"
          d="M73 220c-1 0-2.2-.2-3-1-2.4-1.5-3-4.7-1.2-7l17-23.5c1.7-2.3 5-2.8 7.2-1 2.3 1.5 2.8 4.8 1 7L77 218c-1 1.4-2.5 2-4 2zm38.3-11.6c-1 0-2-.3-3-1-2.4-1.7-3-5-1.2-7.2l9-11.7c2-2.3 5-2.8 7-1s3 4.8 1 7l-8 11.8c-1 1.4-2 2-4 2zM134 220c-1 0-2-.2-3-1-2.2-1.5-2.7-4.7-1-7l17-23.5c1.7-2.3 5-2.8 7.2-1 2.3 1.5 2.8 4.8 1 7l-17 23.5c-1 1.4-2.5 2-4 2z"
        />
        <path
          fill="#666"
          d="M181.6 76.5c-2.6 0-5.3.2-7.8.6-3.8-21-21-37-42.7-40h-5c-6 0-12 1-17 3-19 7-32 25-32 46v2h-7c-13 0-25 7-32 17v1c-5 7-8 16-8 25 0 23 18 42 41 42h112c25 0 46-22 46-47s-20-48-46-48z"
        />
      </symbol>
      <symbol id="symbol-13" viewBox="0 0 256 256">
        <path
          fill="#E6E6E6"
          d="M211 205l-10.2-3.2 6-8c1.7-2.2 1.3-5.4-1-7.2-2.3-1.7-5.6-1.2-7.3 1l-6.8 9-6.7-8.2c-1.8-2.3-5-2.6-7.3-.8s-2.6 5-.8 7.3l6 8-10 3c-3 1-4 4-3 6 0 2 2 3 4 3 0 0 1 0 2-1l10-4v10c0 3 2 5 5 5s5-3 5-6v-10l9 3h1c2 0 4-1 5-4s-1-6-4-7zm-116.8 0l-10-3.2 5.8-8c1.8-2.2 1.3-5.4-1-7.2-2.2-1.7-5.5-1.2-7.2 1l-7 9-6.6-8.2c-1.8-2.3-5-2.6-7.3-.8s-3 5-1 7.3l7 8-10 3c-3 1-4 4-3 6 0 2 2 3 5 3l2-1 10-4v10c0 3 2 5 5 5s5-3 5-6v-11l9 3h1c2 0 4-1 5-4s-1-6-4-7zm55-6.6l-9.3 3V191c0-2.7-3-5-5-5-3 0-6 2-6 5v10l-10-3.8c-3-1-6 .4-7 3-1 2.8 0 5.8 3 6.8l9 3.3-7 8.5c-2 2.3-2 5.5 0 7.3 1 1 2 2 3 2s3 0 4-2l6-8 7 9c1 2 2 2 4 2 1 0 2 0 3-1 2-1 3-5 1-7l-6-8 10-3c3-1 4-4 3-6s-4-4-7-3z"
        />
        <path
          fill="#666"
          d="M181.6 76.5c-2.6 0-5.3.2-7.8.6-3.8-21-21-37-42.7-40h-5c-6 0-12 1-17 3-19 7-32 25-32 46v2h-7c-13 0-25 7-32 17v1c-5 7-8 16-8 25 0 23 18 42 41 42h112c25 0 46-22 46-47s-20-48-46-48z"
        />
      </symbol>
      <symbol id="symbol-14" viewBox="0 0 256 256">
        <path
          fill="#666"
          d="M181.6 76c-2.7 0-5.3.2-8 .7-3.7-21.2-21-37.7-42.6-40.3l-6-.3c-6 0-12 2-17.5 4C89 47 75.7 65 75.7 86v1c-2.3-.4-4.6-.6-7-.6-13 0-24.8 6.8-32.2 17h-.2c-5 8-8 16.2-8 25.3 0 22.4 18 42.7 40.4 42.7h113c25 0 45.4-22.3 45.4-47.5s-20-48-45-48z"
        />
        <path
          fill="#E6E6E6"
          d="M187.2 207c-1 0-2-.4-3-1-2.3-1.8-2.8-5-1-7.3l8-10.6c1.7-2 5-2 7.2-1 2.3 2 2.7 5 1 8l-8 11c-1 2-2.6 2-4.2 2z"
        />
        <path
          fill="#E6E6E6"
          d="M202 215.4c-.6 0-1.2 0-1.7-.2l-12.7-4.2c-2.7-1-4.2-3.8-3.3-6.5 1-2.7 3.8-4.2 6.5-3.3l12.7 4.2c2.7 1 4.2 3.8 3.3 6.5-.7 2-2.7 3-5 3z"
        />
        <path
          fill="#E6E6E6"
          d="M186 228c-2.8 0-5-2.3-5-5l-.3-13.6c0-3 2.2-5.4 5-5.4 3 0 5.2 2.4 5.3 5.2l.2 13.5c0 2.8-2.3 5.3-5 5.3h-.2z"
        />
        <path
          fill="#E6E6E6"
          d="M169 217c-2 0-4-1.4-4.8-3.5-1-2.7.4-5.6 3-6.6l12.8-5c2.4-1 5.3 0 6.3 3 1 2-.5 5-3 6l-12.7 4H169zm13.2-9.4c-1.5 0-3-.7-4-2l-8.4-10.4c-1.8-2.2-1.4-5.4.8-7.2 2.2-1.8 5.4-1.4 7.2.8l8.4 10.4c1.8 2.2 1.4 5.5-.8 7.2-1 .8-2 1.2-3.2 1.2z"
        />
        <g fill="#E6E6E6">
          <path d="M71.5 207c-1 0-2-.4-3-1-2.3-1.8-2.8-5-1-7.3l8-10.6c1.7-2 5-2 7.2-1 2.3 2 2.7 5 1 8l-8 11c-1 2-2.6 2-4.2 2z" />
          <path d="M86.2 215.4c-.5 0-1 0-1.6-.2L72 211c-2.8-1-4.2-3.8-3.4-6.5 1-2.7 3.8-4.2 6.5-3.3l13 4.2c3 1 4 3.8 4 6.5 0 2-2 3-5 3z" />
          <path d="M70.3 228c-2.8 0-5-2.3-5-5l-.3-13.6c0-3 2.2-5.4 5-5.4 3 0 5.2 2.4 5.3 5.2l.2 13.5c0 2.8-2.3 5.3-5 5.3h-.2z" />
          <path d="M53.4 217c-2 0-4-1.4-5-3.5-.8-2.7.5-5.6 3.2-6.6l12.6-5c2.7-1 5.6 0 6.6 3 1 2-.4 5-3 6L55 216l-1.6.3zm13-9.4c-1.4 0-3-.7-4-2L54 195.3c-1.7-2.2-1.3-5.4 1-7.2 2-1 5.3-1 7 1l8.5 11c1.8 2 1.4 6-.8 7-1 1-2 1-3.2 1z" />
        </g>
        <path
          fill="#FFB900"
          d="M154.4 132.5c-2.4-1.7-5.7-1.2-7.4 1l-32.3 44c-1.7 2.4-1.2 5.8 1.2 7.5 0 .7 2 1 3 1h14l-18 25.2c-2 2.3-2 5.7 1 7.4 1 .7 2 1 3 1s3-.8 4-2.2l24-33c1-2.3 1-5.6-2-7.4h-1v-.2c-1-.5-2-.8-3-.8h-14l26-36c2-2.4 1-5.8-1-7.5z"
        />
      </symbol>
      <symbol id="symbol-15" viewBox="0 0 256 256">
        <path
          fill="#B3B3B3"
          d="M196.3 198H57.5c-2.8 0-5-2.2-5-5s2.2-5 5-5h138.8c3 0 5.2 2.2 5.2 5s-2.3 5-5.2 5zm.5 23H58c-2.8 0-5-2.2-5-5s2.2-5 5-5h138.8c3 0 5.2 2.2 5.2 5s-2.3 5-5.2 5zM181.4 76c-2.6 0-5.2.3-7.8.7-3.8-21.2-21-37.7-42.7-40.2-2-.3-4-.4-6-.4-7 0-13 2-18 4-19 7-32 25-32 46v1h-7c-13 0-25 7-33 17-5 8-8 16-8 25 0 23 18 43 40 43h113c25 0 45-22 45-47s-20-48-45-48z"
        />
      </symbol>
      <symbol id="symbol-2" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M127.5 48c-3 0-5.5-2.3-5.5-5.2V5.5c0-3 2.6-5.3 5.5-5.3 3 0 5.5 2.4 5.5 5.3v37.3c0 3-2.6 5.3-5.5 5.3zm0 207.8c-3 0-5.5-2.3-5.5-5.2v-37.4c0-3 2.6-5.2 5.5-5.2 3 0 5.5 2.3 5.5 5.2v37.4c0 3-2.6 5.2-5.5 5.2zM203 230c-1.4 0-3-.6-4-2l-23-29c-1.7-2.2-1.2-5.6 1-7.3 2.3-1.8 5.6-1.4 7.4 1l23 29c1.7 2.4 1.3 5.7-1 7.5-1 .7-2 1-3.3 1zM75.2 66.4c-1.6 0-3-.7-4-2L48 35c-1.8-2.2-1.4-5.5 1-7.3 2.2-1.8 5.5-1.4 7.3 1L79 58c2 2 1.5 5.4-1 7-.7 1-2 1.3-3 1.3zM178 64.6c-1.2 0-2.3-.4-3.2-1-2.3-1.7-3-5-1.2-7.4l22-30c1.6-2.4 4.8-3 7.2-1.2 2.3 1.7 3 5 1.2 7.4l-21.8 30c-1 1.4-2.7 2.2-4.3 2.2zm31 42.6c-2.3 0-4.4-1.4-5-3.6-1-2.8.5-5.7 3.3-6.6l35.3-11.5c2.7-1 5.7.6 6.6 3.4 1 2-.6 5-3.4 6l-35.3 12H209zm35.2 64.3c-.5 0-1 0-1.6-.3l-35.3-11.5c-2.8-1-4.3-3.8-3.4-6.6 0-2 3-4 6-3l35 12c2 1 4 4 3 7-1 2-3 4-5 4zM46.6 107.2c-.6 0-1 0-1.7-.2L9 95.5c-2.8-1-4.3-4-3.4-6.6 1-3 4-5 6.6-4L48 97c2.8 1 4.3 3.8 3.4 6.6-.7 2.2-2.8 3.6-5 3.6zm81-42.5c-33 0-60.6 25.7-63 58.3 3-1 6-1.6 9.3-1.6 14 0 25 10 28 23.4 1-.3 3-.4 4-.4 14 0 26 13 26 27.4 0 7.7-4 15-9 20l3 .2c35 0 63-28.5 63-63.6s-28-63.7-64-63.7z"
        />
        <path
          fill="#E6E6E6"
          d="M106.4 144.4c-1.5 0-3 0-4.5.4-3-13.3-14-23.4-29-23.4-4 0-7 .5-10 1.6-11 4-19 14.5-19 27v.6l-4-.3c-13 0-24 11.4-24 24.3 0 13 10 24.4 23 24.4h65c7 0 13-2.7 18-7.3 5-5 8-12.2 8-20 0-14.5-12-27.3-27-27.3z"
        />
      </symbol>
      <symbol id="symbol-20" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M113 108.3c0-16.8 6.5-32 17.2-43.5-32 3.2-57 30.2-57 63 0 35 28.5 63.4 63.4 63.4 18.2 0 34.6-7.6 46-20-2 .3-4.2.5-6.4.5-35 0-63.3-28.4-63.3-63.4z"
        />
      </symbol>
      <symbol id="symbol-21" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M136 104.7c0-17 6.5-32.2 17.2-43.5-32 3.2-57 30.2-57 63 0 35 28.5 63.4 63.5 63.4 18 0 34.5-7.7 46-20-2 .3-4.2.4-6.4.4-35 0-63.4-28.3-63.4-63.3z"
        />
        <path
          fill="#E6E6E6"
          d="M138.4 140.2c-1.5 0-3 0-4.4.4-2.4-13.3-14-23.4-28-23.4-3.3 0-6.5.6-9.5 1.7-11 3-19 14-19 26-1.2-1-2.6-1-4-1-12.8 0-23.2 11-23.2 24s10.4 25 23.2 25h65c6.8 0 13-3 17.6-7.7 6-5 9-12 9-20 0-14-11-27-26-27z"
        />
      </symbol>
      <symbol id="symbol-22" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M181.8 53.2C181.8 40 187 28 195.4 19c-25 2.6-44.7 23.8-44.7 49.6 0 27.5 22.3 49.7 49.8 49.7 14.2 0 27-6 36.2-15.6l-5 .3c-27.6 0-50-22.3-50-49.8z"
        />
        <path
          fill="#E6E6E6"
          d="M186 103.4c-2.8 0-5.6.2-8.4.7-4.4-24-26.2-43-52.3-43-4.7 0-9.2 1-13.5 2l-8 3C85 73 72 92 72 114v1c-1.6-.4-3.3-1-5-1h-5c-23 2-41 22-41 45.3v1.6c.5 23 19.8 45 43.5 45H186c27 0 49-25 49-52s-22-51-49-51z"
        />
      </symbol>
      <symbol id="symbol-23" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M182 50.2C182 37 187.4 25 195.8 16c-25 2.6-44.7 23.8-44.7 49.6 0 27.5 23 49.8 50 49.8 14 0 27-6 36-15.6l-5 .3c-27 0-50-22-50-49z"
        />
        <path
          fill="#E6E6E6"
          d="M186.4 100.4c-3 0-5.7.3-8.5.8-5-25-27-43.8-53-43.8-5 0-9 .6-14 1.7l-8 3c-18 9-31 27-31 49v1c-2 0-4 1-5 0h-5c-23 2-41 23-41 46v1c0 24 19 45 43 45h122c27 0 49-24 49-51s-22-52-49-52z"
        />
        <path
          fill="#4196C8"
          d="M69.3 256.2c-1 0-2.3-.3-3.2-1-2-2-3-5.3-1-7.8l19-25.2c2-2.5 6-3 8-1.3 3 1 3 5 1 7l-19 26c-1 1-2 2-4 2zm41.3-12.6c-1 0-2.3-.4-3.2-1-2.5-2-3-5.3-1.3-7.8l10-12.6c2-2.5 6-3 8-1.3 3 1 3 5 2 7l-9 12c-1 1-2 2-4 2zm24.6 12.6c-1 0-2.2-.3-3.2-1-2.5-2-3-5.3-1.2-7.8l18.3-25.2c2-2.5 6-3 8-1.3 3 1 3 5 2 7l-19 26c-1 1-2 2-4 2zm41.3-12.6c-1 0-2.2-.4-3.2-1-2.5-2-3-5.3-1.2-7.8l10-12.6c2-2.5 6-3 8-1.3 3 1 3 5 2 7l-9 12c-1 1-2 2-4 2z"
        />
      </symbol>
      <symbol id="symbol-24" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M182.5 50.4c0-13.2 5-25.2 13.6-34-25 2.4-44 23.6-44 49.4 0 27.5 23 49.8 50 49.8 15 0 28-6 37-15.7h-5c-27 0-49-23-49-50z"
        />
        <path
          fill="#666"
          d="M186.7 100.6c-2.8 0-5.6.2-8.4.7-4.5-25-26.2-43.7-52.3-43.7-4.7 0-9.2.6-13.5 1.7-2.8.7-5.4 1.7-8 2.8v1c-18.8 8-31.8 27-31.8 49v2h-10c-23 1-41 23-41 46v1c.3 23 19.7 44 43.5 44h121.5c27 0 49-24 49-51s-22-51-49-51z"
        />
        <path
          fill="#4196C8"
          d="M67 255.7c-1.2 0-2.4-.4-3.3-1-2.5-2-3-5.4-1.3-7.8l18.4-26c1.8-3 5.2-3 7.7-2s3 5 1.2 8l-18.3 25c-1 1-2.8 2-4.5 2zm41.2-12.7c-1 0-2.3-.3-3.3-1-3-1.8-3-5.3-2-7.7l9-12.7c1-2.4 5-3 7-1.2s3 5.3 1 7.8l-9 12.6c-2 1.5-3 2.2-5 2.2zm58.4 12.7c-1.2 0-2.3-.4-3.3-1-2.5-2-3-5.4-1.2-7.8l19-26c2-3 6-3 8-2 3 2 3 5 2 8l-19 25c-1 1-2 2-4 2z"
        />
        <path
          fill="#FFB900"
          d="M166.7 169c-2.3-1.8-5.7-1.3-7.4 1L127 214c-1.7 2.3-1 5.7 1.2 7.4 1 .7 2 1 3.2 1h14.2l-18.2 25.2c-1.7 2.3-1.2 5.7 1.2 7.4 1 .7 2 1 3 1 1.8 0 3.4-.8 4.4-2.2l24-33c1.6-2.3 1-5.6-1.3-7.4h-.3v-.2c-1-.5-2-.8-2.8-.8h-14.2l26.5-36c1-2.4 1-5.8-2-7.5z"
        />
      </symbol>
      <symbol id="symbol-25" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M181.5 41c0-13.2 5.2-25.2 13.6-34-25 2.4-44 23.6-44 49.4 0 27.5 23 49.8 50 49.8 15 0 27-6 36-15.6-1 .2-3 .2-5 .2-27 0-50-22.2-50-49.7z"
        />
        <path
          fill="#B3B3B3"
          d="M186.2 91.2c-3 0-5.7.3-8.4.7-4.5-25-26.2-44-52.4-44-4.6 0-9.2 0-13.5 2-3 0-6 1-8 2h-1c-18 9-31 27-31 49v1l-5-1h-5c-23 2-41 22-41 45v1c0 23 20 45 43 45h122c27 0 49-25 49-52s-22-51-49-51z"
        />
        <path
          fill="#E6E6E6"
          d="M203 231l-10.7-3.5 6.3-8.4c2-2 1.4-5-1-7-2.5-2-6-1-7.8 1l-7.3 10-7-9c-2-2-5.5-3-8-1-2.3 2-2.7 6-.8 8l7.3 9-10 4c-2.8 1-4.3 4-3.3 7s3 4 5.3 4h1.8l10.7-3 .2 11c0 3 2.5 6 5.5 6s5.6-3 5.5-6v-12l10 3h1.6c2.4 0 4.5-1 5.3-4 1-3-.6-6-3.5-7z"
        />
        <path
          fill="#4196C8"
          d="M55 247.4c-1.2 0-2.3-.3-3.3-1-2.5-2-3-5.3-1.2-7.8l18.3-25.2c1.8-2.5 5.2-3 7.7-1.2s3 5.2 1.2 7.7l-18.3 25c-1 1-2.7 2-4.5 2zm41.2-12.6c-1 0-2.3-.4-3.2-1-2.5-2-3-5.3-1.3-7.8l9.2-12.6c1-2.5 5-3 7-1.2s3 5.2 1 7.7l-10 12c-1 1-3 2-5 2zm24.6 12.6c-1 0-2.3-.3-3.2-1-2.5-2-3-5.3-1.3-7.8l18.4-25.2c1.8-2.5 5.2-3 7.7-1.2s3 5.2 1.2 7.7l-18.3 25c-1 1-2.8 2-4.5 2z"
        />
      </symbol>
      <symbol id="symbol-26" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M181.8 41c0-13.2 5.2-25.3 13.6-34.2-25 2.6-44.7 23.8-44.7 49.6 0 27.5 22.3 50 49.8 50 14.3-.2 27.2-6.2 36.2-15.8-1.6.2-3.3.3-5 .3-27.6 0-50-23-50-50z"
        />
        <path
          fill="#B3B3B3"
          d="M186.5 91.2c-3 0-5.7.3-8.5.8-4.5-25-26.2-43.8-52.4-43.8-4.7 0-9.2.6-13.5 1.7-2 0-5 1-8 2-18 8-31 27-31 49v1c-1-1-3-1-5-1h-5c-23 2-41 22-41 45v1c1 23 20 45 44 45h121c27 0 49-25 49-52s-22-51-49-51z"
        />
        <path
          fill="#E6E6E6"
          d="M210 228.5l-10.7-3.5 6.4-8.4c2-2.5 1.4-6-1-7.8-2.5-2-6-1.4-7.8 1l-8 9.7-7-9c-2-2.2-6-2.6-8-.7-3 2-3 5.4-1 7.8l7 9-10 3.6c-3 1-5 4.2-4 7 1 2.3 3 3.7 5 3.7 0 0 1-1 2-1l10-4v11c0 3 2 5 6 5 3 0 5-3 5-6v-11l10 3h2c2 0 4-2 5-4 1-3-1-6-4-7zm-124.6 0L74.6 225l6.4-8.4c1.8-2.5 1.3-6-1-7.8-2.5-2-6-1.4-8 1l-7.2 9.7-7-9c-2-2.2-5.5-2.6-8-.7-2.3 2-2.7 5.4-.8 7.8l7.3 9-10 3.6c-2.8 1-4.3 4.2-3.3 7 .8 2.3 3 3.7 5.2 3.7.7 0 1.3-1 2-1l10.6-4 .2 11c0 3 2.5 5 5.5 5 3.2 0 5.6-3 5.6-6v-11l10 3h2c3 0 5-2 5-4 1-3 0-6-3-7zm59-7l-10 3.2v-11.4c0-3-2.4-5.6-5.4-5.7-3 0-5.6 2.4-5.7 5.5v11l-10.8-4c-3-1-6 1-7 4s.3 6 3.2 7l10 4-7.3 9c-2 2-1.5 6 .8 8 1 1 2.3 2 3.5 2 1.6 0 3.2-1 4.3-2l7.2-9 7.3 10c1 2 2.8 2 4.4 2 1 0 2 0 3-1 2-2 3-5 1-8l-7-8 11-3c3-1 4-4 3-7s-4-4-7-3z"
        />
      </symbol>
      <symbol id="symbol-3" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M104 62.8c-3 0-5-2.5-5-5.5v-31c0-3 2-5.6 5-5.6s5 2.5 5 5.5v31c0 3-2 5.6-5 5.6zM60 78.5c-1.6 0-3.2-.7-4.3-2l-19.4-25c-2-2.3-1.4-5.8 1-7.7 2.4-1.8 6-1.4 7.8 1l20 24.8c2 2.4 2 6-1 7.8-1 .8-2 1-3 1zm-24.2 34.7c-.6 0-1.2 0-1.7-.3L5 103c-3-1-4.6-4-3.6-7 1-3 4-4.5 7-3.5l30 9.7c2.8 1 4.4 4 3.5 7-1 2.3-3 3.8-6 3.8zm78.8-34.6c-4.7 0-9.2.5-13.6 1.4-30.2 6.2-53 33-53 65 0 6 .8 11.7 2.3 17.2 8-11 20.5-18.4 34.7-18.4 2.5 0 5 .2 7.5.6V143c0-22.6 14.2-42 34.3-49.7 6-2.2 12.3-3.5 19-3.5 2.2 0 4.3.2 6.4.4-10.7-7.3-23.7-11.6-37.6-11.6z"
        />
        <path
          fill="#E6E6E6"
          d="M206.7 132.8c-3 0-5.7.3-8.4.8-4.2-22.8-22.8-40.6-46-43.4-2.2-.2-4.3-.4-6.5-.4-6.7 0-13 1.3-19 3.5-20 7.7-34.3 27-34.3 49.8v2H85c-14.2 0-26.7 7-34.7 18H50c-5.3 8-8.6 18-8.6 28 0 23 19.6 45 43.6 45h122c27 0 49-24 49-51s-22-51-49-51z"
        />
      </symbol>
      <symbol id="symbol-4" viewBox="0 0 256 256">
        <path
          fill="#E6E6E6"
          d="M186 103.3c-2.8 0-5.6.3-8.3.7-4.5-24.8-26.3-43.7-52.4-43.7-4.7 0-9.2.6-13.5 1.7-23 6-39.8 26.8-39.8 51.6v1.3c-2.4-1-5-1-7.5-1-24 0-43.7 21-43.7 46v1c.6 23 20 45 43.7 45H186c27.2 0 49.2-25 49.2-52s-22-52-49-52z"
        />
      </symbol>
      <symbol id="symbol-5" viewBox="0 0 256 256">
        <path
          fill="#4196C8"
          d="M92 255.7c-1 0-2.2-.4-3-1-2.5-1.8-3-5-1.3-7.4l17.5-24c1.7-2.4 5-3 7.3-1.3 2.4 1.8 3 5 1.2 7.4l-17.5 24c-1 1.5-2.6 2.3-4.2 2.3zm39.3-12c-1 0-2-.4-3-1-2.4-1.8-3-5-1.2-7.5l9-12c2-2.3 5-3 8-1.2s3 5 1 7.4l-8 12c-1 1.5-2 2.2-4 2.2zm23.5 12c-1 0-2.2-.4-3-1-2.5-1.8-3-5-1.3-7.4l17.5-24c1.7-2.4 5-3 7.4-1.3 2.3 1.8 3 5 1 7.4l-17.3 24c-1 1.5-2 2.3-4 2.3zm39.2-12c-1 0-2-.4-3-1-2.3-1.8-2.8-5-1-7.5l8.6-12c1.7-2.3 5-3 7.4-1.2 2.4 1.8 3 5 1.2 7.4l-8.8 12c-1 1.5-2.6 2.2-4.3 2.2z"
        />
        <path
          fill="#FFB900"
          d="M105 40.5c-3 0-6-2.4-6-5.3V5.6c0-3 3-5.2 6-5.2s6 2.3 6 5.2v29.6c0 3-3 5.3-6 5.3zm-41.7 15c-1.6 0-3-.7-4-2L40.6 29.8c-1.8-2.3-1.4-5.6 1-7.4 2.2-1.8 5.5-1.4 7.3 1L67 47c1.8 2.3 1.4 5.6-1 7.4-1 .7-2 1-3.2 1zm-23 33c-.6 0-1 0-1.7-.3L10 79c-2.7-1-4.2-4-3.3-6.7 1-2.7 4-4.3 6.6-3.4l28.5 9c2.8 1 4.3 4 3.4 6-.7 2-2.8 3-5 3zm75-33c-4.5 0-8.8.5-13 1.3-28.8 6-50.4 31.5-50.4 62 0 5.7 0 11.2 2 16.4 7-10.5 19-17.6 33-17.6 2 0 5 .2 7 .6V117c0-21.7 13-40 32-47.4 5-2.2 11-3.4 18-3.4 2 0 4 .2 6 .4-10-7-23-11-36-11z"
        />
        <path
          fill="#E6E6E6"
          d="M202.8 107.2c-2.7 0-5.4.3-8 .7-4-22-21.7-39-44-42l-6-1c-6.4 0-12.5 1-18 3C107.5 77 94 95 94 117v1.2c-2.3-.4-4.7-.6-7-.6-13.6 0-25.5 7-33 17.4h-.3c-5.3 8-8.4 16.7-8.4 26 0 23 18.6 44 41.6 44h115c25 0 46-23 46-49s-21-48.8-47-48.8z"
        />
        <path
          fill="#AFD7FF"
          d="M101 107.7s0 .2-.2.2h.3v-1zm0 0s0 .2-.2.2h.3v-1z"
        />
      </symbol>
      <symbol id="symbol-6" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M100 40.6c-3 0-6-2.4-6-5.3V5.5c0-3 3-5.3 6-5.3s6 2.4 6 5.3v29.8c0 3-3 5.3-6 5.3zm-42.5 15c-1.5 0-3-.6-4.2-2L34.8 30c-1.8-2.5-1.4-5.8 1-7.6 2.2-1.8 5.5-1.4 7.4 1L61.7 47c1.8 2.4 1.4 5.7-1 7.5-1 .8-2 1.2-3.2 1.2zM34.3 89c-.6 0-1-.2-1.7-.4L4 79.3c-2.8-1-4.4-4-3.5-6.7 1-2.8 4-4.3 6.7-3.4L36 78.5c2.7 1 4.2 4 3.3 6.7-.7 2.3-2.8 3.7-5 3.7zm75.5-33.3c-4.5 0-8.8.4-13 1.3-29 6-50.8 31.7-50.8 62.4 0 5.7.8 11.2 2.2 16.4 7.6-10.5 19.7-17.6 33.2-17.6 2.5 0 5 .2 7.3.6v-1.2c0-21.8 13.6-40.4 32.8-47.8 5.7-2 11.8-3.3 18.2-3.3 2 0 4 0 6 .3-10-7-22.5-11-36-11z"
        />
        <path
          fill="#666"
          d="M198 107.7c-2.8 0-5.5.3-8 .7-4-22-22-39-44.3-41.6l-6-.3c-6.5 0-12.7 1.2-18.3 3.3-19.2 7.4-33 26-33 47.8v1.2c-2.2-.4-4.6-.6-7-.6-13.7 0-25.7 7-33.3 17.6v.2c-5 7-8 16.6-8 26 0 23.2 19 44 42 44h116c26 0 47-23 47-49s-21-49.3-47-49.3z"
        />
        <path
          fill="#FFB900"
          d="M184.4 168.5c-2.4-1.7-5.7-1.2-7.4 1l-32.3 44c-1.7 2.4-1.2 5.8 1.2 7.5 0 .7 2 1 3 1h14l-18 25.2c-2 2.3-2 5.7 1 7.4 1 .7 2 1 3 1s3-.8 4-2.2l24-33c1-2.3 1-5.6-2-7.4h-1v-.2c-1-.5-2-.8-3-.8h-14l26-36c2-2.4 1-5.8-1-7.5z"
        />
        <path
          fill="#4196C8"
          d="M85.6 256c-1 0-2.2-.3-3-1-2.5-1.7-3-5-1.3-7.4L99 223.4c1.6-2.4 5-3 7.3-1.2 2.4 1.8 3 5 1.2 7.4L90 254c-1 1.3-2.8 2-4.4 2zm39.6-12c-1 0-2.2-.4-3-1-2.5-1.8-3-5-1.3-7.5l8-12c1-2.5 5-3 7-1.3s3 5 1 7.4l-9 12.2c-1 1.4-3 2-4 2zm56 12c-1 0-2-.3-3-1-2.4-1.7-3-5-1.2-7.4l17.5-24.2c1.8-2.4 5-3 7.5-1.2 2.3 1.8 3 5 1 7.4L185.7 254c-1 1.3-2.7 2-4.4 2z"
        />
      </symbol>
      <symbol id="symbol-7" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M106.5 39.7c-2.8 0-5.5-2.3-5.5-5.2V5.8c0-3 2.7-5.2 5.5-5.2S112 3 112 5.8v28.7c0 3-2.7 5.2-5.5 5.2zM65 54.3c-1.5 0-3-.7-4-2l-18-23c-1.8-2.2-1.4-5.5 1-7.2 2-1 5.3-1 7 1l18 23c1.8 3 1.4 6-1 8-.8 1-2 1-3 1zm-22.5 32c-.5 0-1 0-1.6-.2l-28-9c-3 0-5-3-4-6 1-2 3-4 6-3l28 9c3 1 4 4 3 7-1 2-3 4-5 4zm73-32c-4.3 0-8.5.4-12.6 1.3-28 5.8-49 30.6-49 60.3 0 5 0 10 2 15 7-10 19-17 32-17h7v-1c0-21 13-39 31-46 5-2 11-3 17-3h6c-10-6-22-10-35-10z"
        />
        <path
          fill="#666"
          d="M200.8 104.6c-2.6 0-5.2.2-7.8.6-3.8-21-21-37.6-42.6-40-2-.4-4-.5-6-.5-6.2 0-12 1-17.6 3.2C108.3 75 95 93 95 114v1.3c-2.2-.4-4.5-.6-7-.6-13 0-24.7 7-32 17v.3h-.2c-5 7-8.2 16.2-8.2 25.4 0 22.3 18 42.6 40.5 42.6h113c26 0 46-22.6 46-47.7s-20-47.7-45-47.7z"
        />
        <path
          fill="#E6E6E6"
          d="M216.5 233.8l-10-3.3 6-7.8c1.6-2.2 1.2-5.4-1-7.2-2.3-1.7-5.6-1.2-7.3 1l-6.8 9-6.6-8.3c-1.8-2.2-5-2.5-7.2-.7s-2.6 5-.8 7.2l6.7 8.3-9.2 3.3c-2.6 1-4 4-3 6.6.7 2 2.7 3 4.8 3 1 0 2 0 2-1l10-4v9c0 3 3 5 5 5 3 0 6-2 5-5v-10l10 3h2c2 0 4-2 5-4 1-3 0-6-3-7z"
        />
        <path
          fill="#4196C8"
          d="M79 249c-1 0-2-.3-3-1-2.3-1.7-2.8-5-1-7.2l17-23.4c1.6-2.3 4.8-2.8 7-1 2.4 1.6 3 4.8 1.2 7l-17 23.5c-1 1-2.6 2-4 2zm38.4-11.7c-1 0-2-.3-3-1-2.4-1.7-3-5-1.2-7.2l8.5-11c1.7-2 5-3 7.2-1 2 2 2 5 1 7l-9 12c-1 2-3 2-4 2zm22.8 11.7c-1 0-2-.3-3-1-2.3-1.7-2.8-5-1.2-7.2l17-23.4c1.7-2.3 5-2.8 7.2-1 2.3 1.6 2.8 4.8 1 7l-17 23.5c-1 1-2.4 2-4 2z"
        />
      </symbol>
      <symbol id="symbol-8" viewBox="0 0 256 256">
        <path
          fill="#FFB900"
          d="M106 39.2c-2.8 0-5-2.3-5-5v-29c0-2.7 2.2-5 5-5s5 2.3 5 5V34c0 3-2.2 5.2-5 5.2zM65.3 53.8c-1.6 0-3-.7-4-2l-18-23c-1.7-2-1.3-5.4 1-7 2-1.8 5.4-1.4 7 .8l18 23c1.7 2 1.3 5.3-1 7-.8.8-2 1.2-3 1.2zm-22.5 32c-.5 0-1 0-1.6-.3l-27.6-9c-2.7-.8-4.2-3.7-3.3-6.4 1-2 3.8-4 6.5-3l27.6 9c2.7 1 4.2 4 3.3 7-.7 2-2.7 4-5 4zm72.8-32c-4.3 0-8.5.4-12.5 1.3-28 6-48 31-48 61 0 6 1 11 2 16 8-10 19-17 32-17 3 0 5 1 7 1v-2c0-21 14-39 32-46 6-2 12-3 18-3 2 0 4 0 6 1-10-7-22-11-35-11z"
        />
        <path
          fill="#666"
          d="M200.5 104c-2.6 0-5.2.2-7.7.6-3.8-21-21-37.5-42.6-40-2-.3-4-.4-6-.4-6 0-12 1-17.5 3.2-18.5 7-31.6 25-31.6 46v1.2c-2-.4-4-.6-7-.6-13 0-24 6.8-32 17-5 7-8 16.2-8 25.4 0 22.3 18 42.6 41 42.6h112c25 0 46-22.5 46-47.5S226 104 201 104z"
        />
        <path
          fill="#AFD7FF"
          d="M91.6 104.4s0 .2-.2.2h.2v-.2zm0 0s0 .2-.2.2h.2v-.2z"
        />
        <path
          fill="#E6E6E6"
          d="M222.4 232.8l-10-3.2 6-7.8c1.6-2.3 1.2-5.5-1-7.2-2.3-1.7-5.5-1.3-7.2 1l-6.8 9-6.6-8.3c-1.8-2.2-5-2.6-7.2-.8s-2.5 5-.8 7.2l6.7 8.3-9 3.3c-2.8 1-4.2 4-3.2 6.6.7 2 2.7 3 4.8 3 1 0 2 0 2-1l10-4v11c0 2 3 5 5 5 3 0 6-3 5-6v-10l10 3h2c2 0 4-2 5-4 1-3 0-6-3-7zm-115.2 0l-10-3.2 6-7.8c1.6-2.3 1.2-5.5-1-7.2-2.3-1.7-5.6-1.3-7.3 1l-7 9-7-8.3c-2-2.2-5-2.6-7-.8s-3 5-1 7.2l7 8.3-9 3.3c-3 1-4 4-3 6.6 0 2 2 3 5 3 0 0 1 0 1-1l10-4v11c0 2 2 5 5 5s5-3 5-6v-10l9 3h1c2 0 4-2 5-4 1-3-1-6-4-7zm54.4-6.6l-9.3 3 .2-10.7c0-3-2.2-5.5-5-5.5-3 0-5.2 2.5-5.3 5.3v10l-10-3.5c-2.6-1-5.6.4-6.5 3-1 2.7.4 5.7 3 6.6l9.2 3.3-7 8.4c-2 3-2 6 1 8 1 1 2 1 3 1s3 0 4-2l6-8 7 9c1 1 2 2 4 2 1 0 2 0 3-1 2-2 3-5 1-7l-6-7 10-3c2-1 4-4 3-6-1-3-4-4-7-3z"
        />
      </symbol>
      <symbol id="symbol-9" viewBox="0 0 256 256">
        <path
          fill="#4196C8"
          d="M74.2 220c-1 0-2-.4-3-1-2.3-1.7-2.8-5-1.2-7.2l17-23.4c1.7-2.3 5-2.8 7.2-1 2.2 1.5 2.8 4.8 1 7l-17 23.4c-1 1.4-2.4 2-4 2zm38.2-11.8c-1 0-2-.3-3-1-2.3-1.6-2.8-4.8-1.2-7l8.5-11.8c1.7-2.3 5-2.8 7.2-1 2 1.5 2 4.7 1 7l-9 11.6c-1 1.8-3 2.5-4 2.5zM135 220c-1 0-2-.4-3-1-2.2-1.7-2.7-5-1-7.2l17-23.4c1.6-2.3 4.8-2.8 7-1 2.4 1.5 3 4.8 1.2 7l-17 23.4c-1 1.4-2.5 2-4 2zm38.3-11.8c-1 0-2-.3-3-1-2.3-1.6-2.8-4.8-1-7l8.4-11.8c1.6-2.3 4.8-2.8 7-1 2.4 1.5 3 4.7 1.3 7l-8.5 11.7c-1 2-2.6 3-4.2 3z"
        />
        <path
          fill="#B3B3B3"
          d="M181.7 76c-2.6 0-5.2 0-7.8.5-4-21-21-37.5-43-40-2-.3-4-.4-6-.4-6 0-12 2-18 4-19 7-32 25-32 46v1h-7c-13 0-25 7-32 17-6 7-9 17-9 26 1 22 19 42 41 42h113c25 0 45-22 45-47s-19-49-45-49z"
        />
      </symbol>
      <use href={props.name}></use>
    </svg>
  );
};

export default SvgIcon;
