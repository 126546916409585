import React from "react";
import GovermentLogo from "./images/government_new.svg";

type Props = {};

const Header = (props: Props) => {
  return (
    <div className="container">
      <div className="row mt-3 mb-3">
        <div className="col-lg-12 d-flex justify-content-between align-items-center">
          <img src={GovermentLogo} alt="gov Logo" width={140} />
          <img
            src="https://pcfc.ae/_catalogs/masterpage/en-us/2/assets/images/logos/pcfc.svg"
            className="float-end"
            width="215"
            alt="pcfc logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
