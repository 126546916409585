import React from "react";
import { useI18nContext } from "./i18n/i18n-react";
import LanguageSelection from "./LanguageSelection";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";

type Props = {};

const ShoreSection = (props: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { LL, locale } = useI18nContext();

  const onTypeSelected: React.ChangeEventHandler<HTMLSelectElement> = async ({
    target,
  }) => {
    const val = target.value as String;
    navigate(`/${val}`);
  };

  return (
    <div className="menu-bg">
      <div className="container py-1 py-md-0">
        <div
          className="d-flex justify-content-between align-items-center"
          dir={locale === "ar" ? "rtl" : "ltr"}
        >
          <div className="d-none d-md-flex">
            <div className="shore-menu">
              <ul className="list-unstyled m-0 d-flex justify-content-between align-items-center p-0">
                <li>
                  <Link
                    to="/nearshore"
                    className={`${
                      pathname?.includes("nearshore") ? `active` : ""
                    }`}
                  >
                    {LL.near_shore()}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/offshore"
                    className={`${
                      pathname?.includes("offshore") ? `active` : ""
                    }`}
                  >
                    {LL.off_shore()}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-block d-md-none">
            <label>
              <select
                // className="form-select bg-transparent border border-0 shadow-none"
                className="language-select"
                value={
                  pathname?.includes("nearshore") ? `nearshore` : "offshore"
                }
                onChange={onTypeSelected}
                style={{
                  backgroundColor: "#2b6182",
                  color: "white",
                  border: "none",
                  boxShadow: "none",
                  outline: "none",
                  textTransform: "uppercase",
                }}
              >
                <option value={"nearshore"} style={{}}>
                  {LL.near_shore()}
                </option>
                <option value={"offshore"} style={{}}>
                  {LL.off_shore()}
                </option>
              </select>
            </label>
          </div>
          <div className="">
            <div className="d-flex justify-content-end align-items-center h-100">
              <LanguageSelection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoreSection;
