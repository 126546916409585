import React, { LegacyRef, SyntheticEvent } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import { DayBase } from "./models";
import HoursStepTabItem from "./HoursStepTabItem";
import useMediaQuery from "./hooks";
import { DataContext } from "./DataContext";
import WeatherContentTab from "./WeatherContentTab";

// const responsive = {
//   0: {
//     items: 1,
//     itemsFit: "contain",
//   },
//   568: {
//     items: 1,
//     itemsFit: "contain",
//   },
//   700: {
//     items: 1,
//     itemsFit: "contain",
//   },
//   1024: {
//     items: 2,
//     itemsFit: "contain",
//   },
//   1370: {
//     items: 4,
//     itemsFit: "contain",
//   },
// };

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
    // partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
    // partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    // partialVisibilityGutter: 90,
  },
};

type Props = {
  items: DayBase[] | null | undefined;
  onSlideChanged: (i: number) => void;
  activeIndex: number;
  isRTL: boolean;
  tides: any;
};

const Slider = React.forwardRef(function Slider(
  props: Props,
  sRef: LegacyRef<Carousel> | undefined
) {
  const matches = useMediaQuery("(min-width: 1024px)");

  return props.items ? (
    <Carousel
      ref={sRef}
      swipeable={false}
      draggable={false}
      showDots={matches}
      responsive={responsive}
      ssr={true} // means to render carousel on server-side.
      infinite={false}
      autoPlay={false}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
      rtl={props.isRTL}
      arrows={false}
      // deviceType={this.props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-padding"
    >
      {props.items.map((item, ind) => (
        <WeatherContentTab
          key={`c-${ind}`}
          data={item}
          isRTL={props.isRTL}
          tides={props.tides}
        />
      ))}
    </Carousel>
  ) : (
    <></>
  );
});

export default Slider;
