import React from "react";
import { DataContext } from "./DataContext";
import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css";
import Slider from "./Slider";
import WeatherHeaderTab from "./WeatherHeaderTab";
import { useI18nContext } from "./i18n/i18n-react";
import Carousel from "react-multi-carousel";

type Props = {};

const Weather = (props: Props) => {
  const context = React.useContext(DataContext);
  const { LL, locale } = useI18nContext();

  if (context.loading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container justify-content-center">
        <h1 className="d-sm-block d-md-block d-lg-none fc-heading text-center bold mt-3">
          {LL.forecast()}
        </h1>
        {context.daysWeatherData ? (
          <>
            <div className="d-sm-block d-md-block d-lg-none">
              <WeatherHeaderTab
                data={context.daysWeatherData}
                // onItemClicked={(i: number) => context.onHeaderItemSelect(i)}
                onItemClicked={(i: number) =>
                  context.carouselRef?.current?.goToSlide(i)
                }
              />
            </div>
            <div className="mt-3">
              <Slider
                ref={context.carouselRef}
                isRTL={locale === "ar"}
                onSlideChanged={(i) => {}}
                items={context.daysWeatherData}
                activeIndex={context.selectedIndex}
                tides={context.tidesData}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Weather;
